<template lang="pug">
	div(v-if="item && !item.isNeuro()")
		project-alerts
		.integration
			h2.mb-4.integration__title {{ $t('project.analytics.through') }}
			integration.mb-4

</template>

<script>
import { mapGetters } from 'vuex';
import Integration from '@/components/Common/Integration';
import ProjectAlerts from '@/components/Cards/ProjectAlerts';

export default {
    name: 'ProjectIntegration',
    components: {
        Integration,
        ProjectAlerts,
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
        }),
    },
};
</script>
